
export default {
  data () {
    return {}
  },
  mounted () {
    // console.log(this.$route.fullPath)
  },
  methods: {}
}
