
import QRCode from '~/components/PaymentMethod/QRCodeCollapse.vue'
import QRCS from '~/components/PaymentMethod/QRCSCollapse.vue'
import SCB from '~/components/PaymentMethod/SCBCollapse.vue'
export default {
  async asyncData ({ params, $axios, $cookies, redirect }) {
    try {
      console.log('id', params.id)
      const result = await $axios.$get(`orders/${params.id}`)
      // console.log('payment-checkout', params.id, result)
      return {
        orderID: params.id,
        order: result.data
      }
    } catch (e) {
      console.log('error', e.message)
      redirect('/error/404')
    }
  },
  components: {
    QRCode,
    QRCS,
    SCB
  },
  data () {
    return {
      order: {},
      orderID: null,
      selectedPaymentMethod: null,
      collapses: {
        qrcode: false,
        qrcs: false,
        scb: false
      }
    }
  },
  watch: {
    selectedPaymentMethod (newValue, oldValue) {
      Object.keys(this.collapses).forEach((key) => {
        this.collapses[key] = false
      })
      // Set the selected collapse state to true
      this.collapses[newValue.toLowerCase()] = true
    }
  },
  methods: {
    payment () {
      console.log('--- payment ---')
    }
  }
}
